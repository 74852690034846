// src/components/Inputs/Dropdown.tsx
/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import { css, Theme } from '@emotion/react';
import { useTheme } from '../../context/ThemeContext';
import IconButton from '../IconButton/IconButton';
import { ChevronDown } from 'lucide-react';

interface DropdownOption {
    label: string;
    value: string;
}

interface DropdownProps {
    options: string[] | DropdownOption[];
    onSelect: (value: string) => void;
    placeholder?: string;
    additionalStyles?: ReturnType<typeof css>;
    value?: string;
    selectedValueStyle?: ReturnType<typeof css>;
    disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
    options,
    onSelect,
    placeholder,
    additionalStyles,
    value,
    selectedValueStyle,
    disabled = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useTheme();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        if (disabled) return;
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (optionValue: string) => {
        onSelect(optionValue);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const renderOptions = () => {
        if (options.length === 0) return null;

        if (typeof options[0] === 'string') {
            // Options is string[]
            return (options as string[]).map((option) => (
                <div
                    key={option}
                    css={dropdownOptionStyle(theme)}
                    onClick={() => handleOptionSelect(option)}
                >
                    {option}
                </div>
            ));
        } else {
            // Options is DropdownOption[]
            return (options as DropdownOption[]).map((option) => (
                <div
                    key={option.value}
                    css={dropdownOptionStyle(theme)}
                    onClick={() => handleOptionSelect(option.value)}
                >
                    {option.label}
                </div>
            ));
        }
    };

    const getDisplayValue = () => {
        if (value) {
            if (options.length === 0) {
                return placeholder || 'Select an option';
            }

            if (typeof options[0] === 'string') {
                // Options is string[]
                return value;
            } else {
                // Options is DropdownOption[]
                const selectedOption = (options as DropdownOption[]).find(
                    (option) => option.value === value
                );
                return selectedOption ? selectedOption.label : placeholder || 'Select an option';
            }
        } else {
            return placeholder || 'Select an option';
        }
    };

    return (
        <div css={[dropdownWrapperStyle(theme), additionalStyles]} ref={dropdownRef}>
            <div
                css={[
                    dropdownHeaderStyle(theme),
                    !value && css`color: ${theme.fadedText};`,
                    disabled && css`cursor: not-allowed;`,
                    selectedValueStyle,
                ]}
                onClick={toggleDropdown}
            >
                <span>{getDisplayValue()}</span>
                <IconButton
                    ariaLabel="Account list dropdown"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                    }}
                    size="tiny"
                    disabled={disabled}
                >
                    <ChevronDown />
                </IconButton>
            </div>
            {isOpen && !disabled && <div css={dropdownListStyle(theme)}>{renderOptions()}</div>}
        </div>
    );
};

export default Dropdown;

// Define Styles
const dropdownWrapperStyle = (theme: Theme) => css`
    position: relative;
    width: 100%;
    margin: ${theme.spacing.small} 0;
    display: flex;
`;

const dropdownHeaderStyle = (theme: Theme) => css`
    font-size: 14px;
    padding: ${theme.spacing.small};
    border: 1px solid ${theme.onSurfaceBorder};
    border-radius: ${theme.borderRadius};
    background-color: ${theme.surface};
    color: ${theme.onSurface};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    user-select: none;
`;

const dropdownListStyle = (theme: Theme) => css`
    font-size: 14px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid ${theme.onSurfaceBorder};
    border-radius: ${theme.borderRadius};
    background-color: ${theme.surface};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
`;

const dropdownOptionStyle = (theme: Theme) => css`
    padding: ${theme.spacing.small};
    cursor: pointer;
    color: ${theme.onSurface};
    &:hover {
        background-color: ${theme.primary}33;
    }
`;
